import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import styled from "@emotion/styled"
import ButtonBack from "../components/ButtonBack"
import { Helmet } from "react-helmet"
import TrainingBanner from "../components/TrainingBanner"
import {
    SEO_DESCRIPTION,
    SEO_KEYWORDS,
    extractImageUrlFromPlayerCode,
} from "../utils"
import ReadMoreItem, { ReadMoreContaner } from "../components/ReadMoreItem"

const Title = styled.h1`
    text-align: center;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-bottom: 4rem;
    div:first-of-type {
        margin-right: 1rem;
    }
`

const Text = styled.div`
    max-width: 900px;
`

const Center = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const VideoContentWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    margin-bottom: 2rem;
    @media (max-width: 750px) {
        margin-left: 0;
        margin-right: 0;
    }
`

const VideoContent = styled.div`
    width: 700px;
    @media (max-width: 750px) {
        width: 100%;
    }
`

const PlayerResponsive = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export default props => {
    const {
        title,
        player,
        text,
        keywords,
        description,
    } = props.data.mongodbVpletaysyaVideos

    return (
        <Container>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords || SEO_KEYWORDS} />
                <meta
                    name="description"
                    content={description || SEO_DESCRIPTION}
                />
            </Helmet>
            <Header />
            <TrainingBanner />
            <Space />
            <Content>
                <TitleWrapper>
                    <ButtonBack backUrl="/video">{"<"}</ButtonBack>
                    <Title>{title}</Title>
                </TitleWrapper>
                <VideoContentWrapper>
                    <VideoContent>
                        <PlayerResponsive
                            dangerouslySetInnerHTML={{ __html: player }}
                        />
                    </VideoContent>
                </VideoContentWrapper>
                <Center>
                    <Text>{text}</Text>
                </Center>
                <Space />
                <ReadMoreContaner>
                    {props.pageContext.readMore &&
                        props.pageContext.readMore.length > 0 &&
                        props.pageContext.readMore.map((data, i) => (
                            <ReadMoreItem
                                key={`read_more_${i}`}
                                title={data.title}
                                slug={data.url}
                                imageUrl={extractImageUrlFromPlayerCode(
                                    data.player
                                )}
                            />
                        ))}
                </ReadMoreContaner>
            </Content>
            <Space />
        </Container>
    )
}

export const videoQuery = graphql`
    query singleVideoQuery($id: String!) {
        mongodbVpletaysyaVideos(id: { eq: $id }) {
            description
            keywords
            old_id
            old_link
            player
            text
            title
        }
    }
`
